// import lib
import { lazy } from "react";

const webRoutes = [
  {
    path: "/",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "home" */ "../components/home")
    ),
  },
  {
    path: "/complete-profile",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "home" */ "./../screens/CompleteProfile")
    ),
  },
  {
    path: "/signup",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "signup" */ "../components/signup")
    ),
  },
  {
    path: "/login",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "login" */ "../components/login")
    ),
  },
  {
    path: "/home",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "homeOld" */ "./../components/homeold")
    ),
  },
  {
    path: "/jd-matcher",
    exact: true,
    auth:true,
    component: lazy(() =>
      import(/* webpackChunkName: "jdmatcher" */ "./../components/JdMatcher/Home")
    ),
  },

  {
    path: "/cv-parser",
    auth:true,
    exact: true,

    component: lazy(() =>
      import(/* webpackChunkName: "cv_parser" */ "./../components/CvParser/CvParserHome")
    ),
  },
  {
    path: "/jd-matcher/details",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "jd_matcher_detailed_view" */ "./../components/detailedview"
      )
    ),
  },
  {
    path: "/terms-conditions",
    exact: true,

    component: lazy(() =>
      import(
        /* webpackChunkName: "termsConditions" */ "./../components/termsConditions"
      )
    ),
  },
  {
    path: "/communication-checker",
    exact: true,
    auth: true,
    component: 
    lazy(() =>
      import(
        /* webpackChunkName: "communication_checker" */ "./../components/CommunicationChecker/communication-checker"
      )
    ),
  },
  {
    path: "/communication-checker/video-results",
    auth: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "communcication-checker"*/ "./../components/CommunicationChecker/comm-video")
    )
  },
  {
    path: "/communication-checker/email-results",
    auth: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "communcication-checker"*/ "./../components/CommunicationChecker/comm-email")
    )
  },
  {
    path: "/communication-checker/result",
    auth: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "communcication-checker"*/ "../components/CommunicationChecker/comm-video-resview")
    )
  },
  {
    path: "/candidate-view/:tokens",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "communcication-checker"*/ "../components/CommunicationChecker/CandidateView.js")
    )
  },
  {
    path: "/technical-skills-analysis",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "cv_parser" */ "./../components/technical-skills-analysis"
      )
    ),
  },
  {
    path: "/technical-analysis-video",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "technical_analysis_video" */ "./../screens/TechnicalAnalysisVideo"
      )
    ),
  },
  {
    path: "/technical-analysis-voice",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "technical_analysis_voice" */ "./../screens/TechnicalAnalysisVoice"
      )
    ),
  },
  {
    path: "/forget-pass",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "forget_pass" */ "./../screens/ForgetPass")
    ),
  },
  {
    path: "/profile",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "profile_update" */ "./../screens/Update")
    ),
  },
  {
    path: "/resetpass/:tokens",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "reset_password" */ "./../screens/ResetPass")
    ),
  },
  {
    path: "/verify/:tokens",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "verify_token" */ "./../screens/Verify")
    ),
  },

  {
    path: "/reverify",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "reverify_user" */ "./../screens/Reverify")
    ),
  },
  {
    path: "/disclaimer",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "disclaimer" */ "./../components/Disclaimer")
    ),
  },
  {
    path: "/privacy",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "privacy" */ "./../components/Privacy")
    ),
  },

  {
    path: "/cv-results",
    auth: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "cv_results" */ "./../components/CvParser/Cv_result")
    ),
  },
  {
    path: "/cv-resview/:id",
    auth: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "cv_resview" */ "../components/CvParser/Cv_resview")
    ),
  },

  {
    path: "/jd-result",
    auth: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "jd_result" */ "./../screens/Jd_result")
    ),
  },

  {
    path: "/saved-result",
    auth: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "saved_result" */ "./../screens/Saved_res")
    ),
  },
  {
    path: "/res-view/:id",
    auth: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "res_view" */ "./../screens/res_review")
    ),
  },
  {
    path: "/jd-maker",
    auth: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "jdmaker" */ "./../components/Jdmaker")
    ),
  },
  {
    path: "/jd-maker/manual",
    auth: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "jdmaker" */ "./../components/Jdbasicdetails")
    ),
  },
  {
    path: "/resume-formatter",
    auth: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "saved_result" */ "./../components/ResumeFormatter/ResumeFormatter")
    ),
  },
  {
    path: "/resume-formatter/request",
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "saved_result" */ "./../components/ResumeFormatter/ResumeExplorer")
    ),
  },
];

export default webRoutes;
