// API Connection here

// Uncomment this for development server

//const api_connection = "DEV"

// Uncomment this for staging server
const api_connection = "STAGE"

// Uncomment this for production server
// const api_connection = "PROD"

let app_url = "http://localhost:3000"
if(api_connection === "DEV") {
    app_url = "http://localhost:3000"
} else if(api_connection === "STAGE") {
    app_url = "https://stagingapp.hirelake.ai"
    // app_url = "http://localhost:3000"
} else if(api_connection === "PROD") {
    app_url = "https://app.hirelake.ai"
}

export const APP_URL = app_url;
export const API_CONNECTION = api_connection;
