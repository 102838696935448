/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className="footer">
          <Container  >
           
            <Row className=" align-items-center justify-content-md-between " >
              <Col md="12" className="mt-3">
                <div className="copyright">
                  © {new Date().getFullYear()} HireLakeAI All Rights Reserved.
                  <Link target="_blank" rel="noreferrer" to="/privacy">
                     Privacy Policy
                  </Link>
                  &nbsp;&nbsp;&nbsp;
                  <Link
                  target="_blank" rel="noreferrer"
                    to={"/disclaimer"}
                  >
                    Disclaimer
                  </Link>
                  &nbsp;&nbsp;&nbsp;
                  <Link target="_blank" rel="noreferrer" to="/terms-conditions">
                    Terms and Conditions
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Footer;
