import React, {Component} from "react";
import {connect} from "react-redux";
import {
	startLoader,
	stopLoader,
	api_modalOff,
} from "../store/reducers/generalActions";
import {
	add_webhook_url,
	createApp,
	deleteApp,
	fetchUser,
	refreshAppSecret,
} from "../services";
import {Card, CardBody, Modal, CardHeader, Form} from "reactstrap";
import {Button} from "react-bootstrap";
import {notifyError, notifySuccess} from "../utils/Helper";
import "./css/cta.css";
import ClipBoard from "./reusable/ClipBoard";
import {IoAdd, IoRefresh} from "react-icons/io5";
import {Tooltip} from "react-tippy";
import "../components/css/custom_tooltip.css";
import {MdEdit} from "react-icons/md";

class GenerateAPIKey extends Component {
	state = {
		showPassword: false,
		webhook_url: this.props.user?.api_credentials?.bulk_api_webhook_url || "",
		edit_webhook_url: false,
		customStyles: {
			option: (provided, state) => ({
				...provided,
			}),

			container: base => ({
				...base,

				border: this.state.hits_message ? "1px solid red" : "",
				borderRadius: 5,
			}),

			menu: (provided, state) => ({
				...provided,
			}),
			placeholder: base => ({
				...base,
				fontSize: 13,
				color: "#78838f",
			}),
		},
	};

	componentDidMount() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;

		this.setState({
			webhook_url: !this.props.user.api_credentials?.bulk_api_webhook_url
				? ""
				: this.props.user.api_credentials.bulk_api_webhook_url,
		});
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.user.api_credentials?.bulk_api_webhook_url !==
			this.props.user.api_credentials?.bulk_api_webhook_url
		) {
			this.setState({
				webhook_url:
					this.props.user.api_credentials?.bulk_api_webhook_url || "",
			});
		}
	}

	togglePasswordVisibility = () => {
		this.setState({
			showPassword: !this.state.showPassword,
		});
	};

	handleGenerateApp = async () => {
		this.props.dispatch(startLoader());
		this.setState({isLoading: true});
		try {
			const {data: res} = await createApp();
			if (res.success === true) {
				notifySuccess(res.message);
				await this.props.dispatch(fetchUser());
			} else {
				notifyError(res.message);
			}
		} catch (err) {
			console.log(err);
			notifyError("Something went wrong");
		}
		this.setState({isLoading: false});
		this.props.dispatch(stopLoader());
	};

	handleRefreshAppSecret = async () => {
		this.props.dispatch(startLoader());
		this.setState({isLoading: true});
		try {
			const {data: res} = await refreshAppSecret();
			if (res.success === true) {
				notifySuccess(res.message);
				await this.props.dispatch(fetchUser());
			} else {
				notifyError(res.message);
			}
		} catch (err) {
			console.log(err);
			notifyError("Something went wrong");
		}
		this.setState({isLoading: false});
		this.props.dispatch(stopLoader());
	};

	handleDeleteApp = async () => {
		this.props.dispatch(startLoader());
		try {
			const req = {
				clientId: this.props.user.api_credentials.clientId,
				clientSecret: this.props.user.api_credentials.clientSecret,
			};
			console.log(req);
			const {data: res} = await deleteApp(req);
			if (res.success === true) {
				notifySuccess(res.message);
				this.setState({
					webhook_url: "",
				});
				await this.props.dispatch(fetchUser());
			} else {
				notifyError(res.message);
			}
		} catch (err) {
			console.log(err);
			notifyError("Something went wrong");
		}
		this.props.dispatch(stopLoader());
	};

	handleAddWebhookUrl = async () => {
		this.props.dispatch(startLoader());

		const url = this.state.webhook_url;

		if (url.length === 0) {
			notifyError("URL should not be empty.");
			this.props.dispatch(stopLoader());
			return;
		}

		// URL validation regex
		const urlPattern =
			/^(https?:\/\/)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

		if (!urlPattern.test(url)) {
			notifyError("Enter a valid URL.");
			this.props.dispatch(stopLoader());
			return;
		}

		try {
			const req = {
				url: this.state.webhook_url,
			};
			const {data: res} = await add_webhook_url(req);
			if (res.success === true) {
				notifySuccess(res.message);
				await this.props.dispatch(fetchUser());
			} else {
				notifyError(res.message);
			}
		} catch (err) {
			console.log(err);
			notifyError("Something went wrong");
		}
		this.props.dispatch(stopLoader());
	};

	render() {
		return (
			<div>
				<Modal
					isOpen={this.props.contacts}
					centered
					style={{
						minWidth: "max-content",
						maxWidth: "100%",
						width: "45rem",
					}}
				>
					<div
						className='modal-body p-0'
						style={{backgroundColor: "white"}}
					>
						<Card style={{height: "100%", width: "100%"}} className=''>
							<CardHeader
								className=' pb-1'
								style={{backgroundColor: "white"}}
							>
								<button
									aria-label='Close'
									className='close contact'
									data-dismiss='modal'
									type='button'
									onClick={() => this.props.dispatch(api_modalOff())}
								>
									<span
										style={{fontSize: "2rem", margin: "0.5rem"}}
										aria-hidden={true}
									>
										×
									</span>
								</button>
								<div
									style={{
										fontWeight: "550",
										fontSize: "2rem",
									}}
									className='text-dark text-center mt-2'
								>
									{this.props.user?.is_api_user
										? "API Details"
										: "Generate Free API Key"}
								</div>
								<div
									className='d-flex align-items-center justify-content-center'
									style={{gap: "10px"}}
								>
									<a
										target='_blank'
										href={
											"https://documenter.getpostman.com/view/36562682/2sA3drHuNH"
										}
										rel='noreferrer'
										style={{
											height: "fit-content",
											marginTop: "8px",
											width: "max-content",
											color: "#229be9",
										}}
										className='btn-sm btn-link border rounded-pill'
									>
										🔗 See Documentation
									</a>
								</div>
							</CardHeader>
							<CardBody style={{backgroundColor: "white"}}>
								<Form
									role='form'
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										flexDirection: "column",
										padding: "1rem 2rem",
									}}
								>
									{this.props.user?.is_api_user ? (
										<>
											<div className='mt-2' style={{width: "100%"}}>
												<strong>Client Id: </strong>
												<div className='d-flex align-items-center'>
													<input
														style={{
															fontWeight: "400",
														}}
														readOnly
														value={
															this.props.user?.api_credentials
																?.clientId
														}
														className='form-control'
														placeholder={
															this.props.user?.api_credentials
																?.clientId
														}
													/>
													&nbsp;
													<ClipBoard
														link={
															this.props.user?.api_credentials
																?.clientId
														}
													/>
												</div>
											</div>
											<div className='my-3' style={{width: "100%"}}>
												<strong>
													Client Secret:{" "}
													<Tooltip
														position='right'
														html={
															<div className='table-action-hover-tooltip'>
																Refresh Client Secret
															</div>
														}
													>
														<IoRefresh
															onClick={
																this.handleRefreshAppSecret
															}
															style={{
																margin: "0.1rem",
																fontSize: "1.3rem",
																cursor: "pointer",
																transform:
																	"translate(-3px, -3px)",
															}}
														/>
													</Tooltip>
												</strong>
												<strong
													style={{
														width: "100%",
														position: "relative",
													}}
													className='d-flex align-items-center'
												>
													<input
														style={{
															fontWeight: "400",
														}}
														readOnly
														className='form-control'
														value={
															this.props.user?.api_credentials
																?.clientSecret
														}
														placeholder={
															this.props.user?.api_credentials
																?.clientSecret
														}
														type={
															this.state.showPassword
																? "text"
																: "password"
														}
													/>
													<input
														type='password'
														id='password-hidden'
														value={
															this.props.user?.api_credentials
																?.clientSecret
														}
														style={{
															display: "none",
														}}
														readOnly
													/>
													<button
														type='button'
														className='btn btn-sm'
														style={{
															position: "absolute",
															top: "50%",
															transform: "translateY(-50%)",
															right: "65px",
															background: "#229be9",
															color: "white",
															height: "25px",
															fontSize: "11px",
															marginRight: "2px",
														}}
														onClick={
															this.togglePasswordVisibility
														}
													>
														{this.state.showPassword
															? "Hide"
															: "Show"}
													</button>
													&nbsp;
													<ClipBoard
														link={
															this.props.user?.api_credentials
																?.clientSecret
														}
													/>
												</strong>
											</div>
											<hr
												style={{
													width: "90%",
													height: "0.7px",
												}}
											/>
											<div
												className='my-3 text-center'
												style={{width: "90%"}}
											>
												{this.props.user?.api_credentials
													?.bulk_api_webhook_url
													? "We will be sending the responses of your bulk api requests in this Webhook URL"
													: "You need to add a Webhook URL to access our Bulk API solution"}
											</div>
											<div className='my-3' style={{width: "100%"}}>
												{this.props.user?.api_credentials
													?.bulk_api_webhook_url && (
													<strong>Webhook URL: </strong>
												)}
												<strong
													style={{
														width: "100%",
														position: "relative",
													}}
													className='d-flex align-items-center'
												>
													<input
														style={{
															fontWeight: "400",
														}}
														className='form-control'
														value={this.state.webhook_url}
														onChange={e => {
															this.setState({
																webhook_url: e.target.value,
															});
														}}
														placeholder={
															!this.props.user?.api_credentials
																?.bulk_api_webhook_url
																? "Add Webhook url"
																: "Enter Webhook url to be updated"
														}
														type='text'
													/>

													{!this.props.user?.api_credentials
														?.bulk_api_webhook_url && (
														<>
															&nbsp;
															<Button
																type='button'
																style={{
																	color: "#229be9",
																	borderColor: "#229be9",
																	background: "white",
																	padding:"4px 10px"
																}}
																onClick={
																	this.handleAddWebhookUrl
																}
															>
																<IoAdd
																	style={{
																		fontWeight: "bolder",
																		fontSize: "2rem",
																		padding: "6px"
																	}}
																/>
															</Button>
														</>
													)}
													{this.props.user?.api_credentials
														?.bulk_api_webhook_url && (
														<>
															&nbsp;
															<Button
																type='button'
																style={{
																	color: "#229be9",
																	borderColor: "#229be9",
																	background: "white",
																	padding:"4px 10px"
																}}
																onClick={
																	this.handleAddWebhookUrl
																}
															>
																<MdEdit
																	style={{
																		fontWeight: "bolder",
																		fontSize: "2rem",
																		padding: "6px"
																	}}
																/>
															</Button>
														</>
													)}
												</strong>
											</div>
											<div
												onClick={() => this.handleDeleteApp()}
												className='btn btn-danger mt-3'
											>
												Delete App
											</div>
										</>
									) : (
										<>
											<span className='d-block'>
												Generate API Client ID and Secret Now! (No
												registered apps found at the moment)
											</span>
											<div
												onClick={this.handleGenerateApp}
												style={{
													background: "#229be9",
													color: "white",
												}}
												className='btn mt-3'
											>
												Generate
											</div>
										</>
									)}
								</Form>
							</CardBody>
						</Card>
					</div>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
		cta_on: state.generalActions.contact_modal,
	};
};

export default connect(mapStateToProps)(GenerateAPIKey);
