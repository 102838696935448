import React from "react";
import ReactDOM from "react-dom";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./App.css";
import ReduxToastr from "react-redux-toastr";
import { stopLoader } from "./store/reducers/generalActions";
import reportWebVitals from "./reportWebVitals";
import { authCheck } from "./store/reducers/auth";

store.dispatch(authCheck());
if (window.performance) {
  store.dispatch(stopLoader());
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={true}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
      />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(////console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
