/* eslint-disable no-unused-vars */
import { toastr } from "react-redux-toastr";
//import _ from "lodash";
import moment from "moment";
import _ from "lodash";
import { confirm } from "react-confirm-box";

export function convertUTCToLocal(mydate) {
  return moment.utc(mydate).local();
}

export function notifySuccess(msg) {
  toastr.success(msg, "");
}

export function notifyError(msg) {
  toastr.error(msg, "");
}

export function notifyWarning(msg) {
  toastr.warning(msg, "");
}

export function notifyInfo(msg) {
  toastr.info(msg, "");
}

export function randomKey() {
  return Math.ceil(Math.random() * 10000);
}

export function isLiveDone() {
  var GivenDate = "2021-10-31 23:59:59";
  var CurrentDate = new Date();
  GivenDate = new Date(GivenDate);

  if (GivenDate >= CurrentDate) {
    return false;
  }
  return true;
}

export function checkEnv() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "staging";
}

export function validatefile(filename) {
  filename = filename.toLowerCase();
  var allowed_extension = ["pdf", "docx", "doc"];
  var arr = filename.split(".");
  ////console.log(arr)
  return allowed_extension.some((el) => filename.endsWith(el));
}

export function validateboth(filename, filetype) {
  // console.log(typeof filetype)
  filename = filename.toLowerCase();
  var allowed_extension = ["pdf", "docx", "mp4", "doc"];
  if (typeof filetype === "string") {
    allowed_extension = ["pdf", "docx", "doc"];
  }
  var arr = filename.split(".");
  ////console.log(arr)
  return allowed_extension.some((el) => filename.endsWith(el));
}

export function validatefileVideo(filename) {
  filename = filename.toLowerCase();
  // var allowed_extension = ["mp4", "jpg", "png", "jpeg"];
  let allowed_extension = ["mp4"];
  var arr = filename.split(".");
  ////console.log(arr)
  return allowed_extension.some((el) => filename.endsWith(el));
}

export function capitalizeLetters(name) {
  let fullname = name;

  if (!_.isEmpty(fullname)) {
    const words = fullname.split(" ");
    for (let i = 0; i < words.length; i++) {
      if (words[i]) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
    }

    return words.join(" ");
  }
}

export function validatefileVideoCommunication(filename) {
  filename = filename.toLowerCase();
  ////console.log(filename)
  var allowed_extension = ["mp4"];
  var arr = filename.split(".");
  ////console.log(arr)
  return allowed_extension.some((el) => filename.endsWith(el));
}

export function trunacateExtra(text) {
  return text.length > 12 ? text.substring(0, 10) + "..." : text;
}

const MSG = {
  commonAdd: "Record added successfully",
  commonApiError: "Something went wrong. Please try again.",
};

export function truncateString(str, max) {
  if (str) {
    return str.length > max ? str.substr(0, max - 1) + "…" : str;
  }
  return "N/A";
}

export function removeLeadingQuotesfromURL(inputString) {
  // Check if the string starts and ends with double quotes
  if (inputString[0] === '"' && inputString[inputString.length - 1] === '"') {
    // Remove the starting and ending double quotes
    return inputString.slice(1, -1);
  } else {
    // Add double quotes if not present
    return `${inputString}`;
  }
}

// export async function lookupCountry({ latitude, longitude }) {
//   const URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

//   const locationData = await fetch(URL).then((res) => res.json());

//   const [{ address_components }] = locationData.results.filter(({ types }) => types.includes('country'));

//   const [{ short_name}] = address_components;

//   return short_name;
// }
export async function confirmDelete() {
  const result = await confirm(
    "Are you sure, entry will be deleted permanantly?",
    {
      closeOnOverlayClick: true,
      labels: {
        confirmable: "Confirm",
        cancellable: "Cancel",
      },
    }
  );
  if (result) {
    return true;
  }
  return false;
}

export default MSG;

export function formatParseDate(dateString) {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Create an Intl.DateTimeFormat object for formatting the date
  const formatter = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Format the date and return it
  return formatter.format(date);
}

export function formatDate(dateString) {
  const options = { month: "short", day: "numeric", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
}

export const processUrls = (inputString) => {
  if (
    !inputString.startsWith("http://") &&
    !inputString.startsWith("https://")
  ) {
    inputString = `http://${inputString}`;
  } else {
    inputString = inputString.replace(/^http:\/\//, "https://");
  }
  // Regular expression to match URLs
  const urlPattern = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;

  // Extract URLs using the regular expression
  const urls = inputString?.match(urlPattern) || [inputString];

  // Validate and format URLs
  return urls.map((url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `http://${url}`;
    }
    return url;
  });
};

export const searchOptions = [
  { value: "Qualifications", label: "Qualifications" },
  { value: "Designation", label: "Designation" },
  { value: "TotalExperienceInRange", label: "Total Experience (Years)" },
  { value: "TotalExperienceInMonths", label: "Total Experience (Months)" },
  { value: "Gender", label: "Gender" },
  { value: "JobProfile", label: "Job Profile" },
  { value: "Address", label: "Address" },
];

export const sortExperienceInYears = (uniqueExperienceRanges) => {
  const filteredExperienceRanges = uniqueExperienceRanges.filter(
    (item) => item.label != null && item.value != null
  );

  const sortedExperienceRanges = filteredExperienceRanges.sort((a, b) => {
    // Check for "+" values and handle them separately
    const isAPlus = a.value.includes("+");
    const isBPlus = b.value.includes("+");

    if (isAPlus && isBPlus) {
      const aPlusValue = parseInt(a.value, 10);
      const bPlusValue = parseInt(b.value, 10);
      return aPlusValue - bPlusValue;
    }

    if (isAPlus) return 1;
    if (isBPlus) return -1;

    // Handle ranges
    const aValueParts = a.value.split("-");
    const bValueParts = b.value.split("-");

    // Convert range values to numbers for comparison
    const aStart = parseInt(aValueParts[0], 10);
    const bStart = parseInt(bValueParts[0], 10);

    return aStart - bStart;
  });

  return sortedExperienceRanges;
};

export const parseNestedJSON = (obj) => {
  if (typeof obj === "string") {
    try {
      obj = JSON.parse(obj);
    } catch (e) {
      // If parsing fails, return the string as is
      return obj;
    }
  }

  if (typeof obj === "object" && obj !== null) {
    for (let key in obj) {
      obj[key] = parseNestedJSON(obj[key]);
    }
  }

  return obj;
};

export function roundToTwoDecimals(number) {
  return Math.round(number * 100) / 100;
}
