const UPDATE_JDPARSER = "UPDATE_JDPARSER";
const REMOVE_JDPARSER = "REMOVE_JDPARSER";
const STORE_DATA = "STORE_DATA";

const UPDATE_SINGLE = "UPDATE_SINGLE";
const UPDATE_EXP = "UPDATE_EXP";
const UPDATE_SKILL = "UPDATE_SKILL";

const PAGE_NO = "PAGE_NO";
const SELECT_JD = "SELECT_JD";
const SET_USER_WEIGHTS = "SET_USER_WEIGHTS";
const EDITTED_JD = "EDITTED_JD";
const SELECT_CV = "SELECT_CV";
const SET_INITIAL = "SET_INITIAL";

export function update_jdparser(payload) {
	return {type: UPDATE_JDPARSER, payload};
}

export function select_jd(payload) {
	return {type: SELECT_JD, payload};
}

export function set_user_weights(payload) {
	return {type: SET_USER_WEIGHTS, payload};
}

export function editted_jd(payload) {
	return {type: EDITTED_JD, payload};
}

export function select_cv(payload) {
	return {type: SELECT_CV, payload};
}

export function current_page(payload) {
	return {type: PAGE_NO, payload};
}

export function remove_jdparser() {
	return {type: REMOVE_JDPARSER};
}

export function store_jdparser() {
	return {type: STORE_DATA};
}

export function update_single(payload) {
	return {type: UPDATE_SINGLE, payload};
}

export function update_exp(payload) {
	return {type: UPDATE_EXP, payload};
}

export function update_skill(payload) {
	return {type: UPDATE_SKILL, payload};
}

export function set_initial() {
	return {type: SET_INITIAL};
}

const initialState = {
	jdparser_data: [],
	page_no: 1,
	singleResult: {},
	updateExp: {},
	selected_jd: "",
	edittedJd: {},
	selected_cv: [],
	skills: [],
	user_weights: {
		mustHaveSkills: 20,
		preferredSkills: 10,
		softSkills: 10,
		roleExperience: 20,
		totalExperience: 10,
		gpa: 5,
		collegeTier: 5,
		certifications: 5,
		achievementsAndMetrics: 5,
		location: 5,
		qualification: 5,
		degree: 0,
		major: 0,
	},
};

export default function reducer(state = initialState, {type, payload = null}) {
	switch (type) {
		case UPDATE_JDPARSER:
			return add_data(state, payload);
		case STORE_DATA:
			return store_data(state, payload);
		case REMOVE_JDPARSER:
			return remove_data(state);
		case UPDATE_SINGLE:
			return updateSingle(state, payload);
		case UPDATE_EXP:
			return updateExp(state, payload);
		case UPDATE_SKILL:
			return updateskill(state, payload);
		case SELECT_JD:
			return selectJd(state, payload);
		case SET_USER_WEIGHTS:
			return setUserWeights(state, payload);
		case EDITTED_JD:
			return edittedJdInfo(state, payload);
		case SELECT_CV:
			return selectCv(state, payload);
		case PAGE_NO:
			return currentPage(state, payload);
		case SET_INITIAL:
			return setInitial(state);
		default:
			return state;
	}
}

function setInitial(state) {
	return {
		jdparser_data: [],
		page_no: 1,
		singleResult: {},
		updateExp: {},
		selected_jd: "",
		selected_cv: [],
		skills: [],
		edittedJd: {},
		user_weights: {
			mustHaveSkills: 20,
			preferredSkills: 10,
			softSkills: 10,
			roleExperience: 20,
			totalExperience: 10,
			gpa: 5,
			collegeTier: 5,
			certifications: 5,
			achievementsAndMetrics: 5,
			location: 5,
			qualification: 5,
			degree: 0,
			major: 0,
		},
	};
}

function selectJd(state, payload) {
	return {
		...state,
		selected_jd: payload,
	};
}

function setUserWeights(state, payload) {
	return {
		...state,
		user_weights: {...payload},
	};
}

function edittedJdInfo(state, payload) {
	return {
		...state,
		edittedJd: payload,
	};
}

function selectCv(state, payload) {
	return {
		...state,
		selected_cv: payload,
	};
}

function currentPage(state, payload) {
	return {
		...state,
		page_no: payload,
	};
}

function updateSingle(state, payload) {
	return {
		...state,
		singleResult: payload,
	};
}

function updateskill(state, payload) {
	return {
		...state,
		skills: payload,
	};
}

function updateExp(state, payload) {
	return {
		...state,
		updateExp: payload,
	};
}

function add_data(state, payload) {
	return {
		...state,
		jdparser_data: payload,
	};
}

function store_data(state, payload) {
	sessionStorage.setItem("JD-result", JSON.stringify(payload));

	return {
		...state,
		jdparser_data: payload,
	};
}

function remove_data(state) {
	return {
		initialState,
	};
}
