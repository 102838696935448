import HTTP from "./../../utils/Http";

// Actions
const AUTH_CHECK = "AUTH_CHECK";
const AUTH_LOGIN = "AUTH_LOGIN";
const AUTH_LOGOUT = "AUTH_LOGOUT";
const SET_USER_DATA = "SET_USER_DATA";
const CLEAR_DATA = "CLEAR_DATA";

const IS_CUSTOM_HIRING = "IS_CUSTOM_HIRING";

// const RESET_NOTIFICATION_COUNT = "RESET_NOTIFICATION_COUNT";
// const UPDATE_BILLING_ADDRESS_FLAG = "UPDATE_BILLING_ADDRESS_FLAG";

// Action Creators
export function closeHiringSolution() {
  return { type: IS_CUSTOM_HIRING };
}

export function authCheck() {
  return { type: AUTH_CHECK };
}

export function authLogin(payload) {
  return { type: AUTH_LOGIN, payload };
}

export function setUserData(payload) {
  return { type: SET_USER_DATA, payload };
}

export function clearUserData() {
  return {type: CLEAR_DATA}
}

export function authLogout() {
  return { type: AUTH_LOGOUT };
}

// export function resetNotificationCount() {
//   return { type: RESET_NOTIFICATION_COUNT };
// }

// export function updateBillingAddressFlag(payload) {
//   return { type: UPDATE_BILLING_ADDRESS_FLAG, payload };
// }

const initialState = {
  isAuthenticated: false,
  user: {},
  currentPlan: {},
};

// Reducer
export default function reducer(
  state = initialState,
  { type, payload = null }
) {
  switch (type) {
    case AUTH_LOGIN:
      return login(state, payload);
    case AUTH_CHECK:
      return checkAuth(state);
    case AUTH_LOGOUT:
      return logout(state);
    case SET_USER_DATA:
      return setLoginUserData(state, payload);
    case CLEAR_DATA:
      return clearData(state)
    case IS_CUSTOM_HIRING:
      return closeCustom(state)
    // case RESET_NOTIFICATION_COUNT:
    //   return resetNotificationCountReducer(state);
    // case UPDATE_BILLING_ADDRESS_FLAG:
    //   return changeBillingAddressFlag(state, payload)
    default:
      return state;
  }
}

function login(state, payload) {
  localStorage.setItem("access_token", payload);
  HTTP.defaults.headers.common["Authorization"] = `Bearer ${payload}`;

  return {
    ...state,
    isAuthenticated: true,
  };
}

function closeCustom(state) {
  return {
    ...state,
    user: {
      ...state.user,
      is_custom_hiring: null,
    },
  }
}

function clearData(state) {
  return {
    ...state,
    user: {}
  }
}

function checkAuth(state) {
  ////console.log("in auth check", state);
  state = Object.assign({}, state, {
    isAuthenticated: !!localStorage.getItem("access_token"),
  });

  if (state.isAuthenticated) {
    HTTP.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return state;
}

function logout(state) {
  localStorage.removeItem("access_token");
  return {
    ...state,
    isAuthenticated: false,
  };
}

function setLoginUserData(state, payload) {
  return {
    ...state,
    user: { ...payload },
    currentPlan: { ...payload.plan_details },
  };
}

